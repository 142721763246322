@font-face {
    font-family:"forma-djr-display";
    src:url("https://use.typekit.net/af/1a1d9c/00000000000000003b9ae4d9/27/l?subset_id=2&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/1a1d9c/00000000000000003b9ae4d9/27/d?subset_id=2&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/1a1d9c/00000000000000003b9ae4d9/27/a?subset_id=2&fvd=n6&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
    }


*,::after,::before{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
a{
    text-decoration: none;
}
ul{
    list-style: none;
}
p,li{
    font-family: Arial, Helvetica, sans-serif;
}
h1,h2,h3,h4{
    font-family: "forma-djr-display";

}